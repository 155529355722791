import React from 'react';
import Layout from '../layouts/page-layout';
import SEO from '../utils/seo';
import SolutionContainer from '../containers/solutionContainer';
import { graphql, useStaticQuery } from 'gatsby';

const CommercialPage = () => {
  const data = useStaticQuery(graphql`
    query {
      commercial: file(relativePath: { eq: "commercial_image.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Layout defaultColor={({ theme }) => theme.colors.lightBg} fixed={false}>
      <SEO
        title="Commercial"
        description="One of our professional exterminating experts will come to your office or any commercial space in the greater St. Louis area, and solve all your pest problems at a time that is convenient for you!"
      />
      <SolutionContainer
        caption="our solutions"
        title="Commercial Pest Control"
        subTitleOne="Free business Inspection"
        textOne="Unitech Pest and Termite Control will partner with your company to deliver outstanding results and exceptional customer care. And, as with every Unitech Pest and Termite Control service, we stand behind everything we do - guaranteed!"
        textTwo="Not only do rats, roaches, and mice spread disease, but they can have a negative impact on the success of your business. Being proactive about pest control means keeping your employees and customers safe. Unitech Pest and Termite provides full-service pest management programs for maximum brand protection."
        textThree="Give us a call at (314) 558 - 4847, or make an appointment online and choose a time that is best for you, and we will send a certified inspector to your place of business to inspect and assess your property completely Free Of Charge."
        image={data.commercial.childImageSharp.fluid}
      />
    </Layout>
  );
};
export default CommercialPage;
